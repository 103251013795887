<template>
  <!-- 项目动态详情页 -->
  <div class="news-detail">
    <div class="news-detail-wrapper" v-wechat-title="`${this.newDetail.title}_双优云桥-智慧研修平台`">
      <div class="detail-box">
        <div class="title-box">
          <p class="title">{{ newDetail.title || '--'}}</p>
          <p class="source">
            <span>{{ newDetail.createTime }}</span>
            <span>来源：{{newDetail.source || '未知'}}</span>
            <span>编辑：{{ newDetail.redactor }}</span>
            <span>阅读量：{{ newDetail.views }}</span>
          </p>
        </div>
        <div v-html="newDetail.detail"></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        newDetail: {},
      };
    },
    mounted() {
      this.getTeacherList(this.$route.params.id);
    },
    methods: {
      async getTeacherList(id) {
        let resData = await this.$Api.DST.getDetail(id);
        // console.log(resData);
        this.newDetail = resData.data;
        this.$store.commit('breadPageNameChange', this.newDetail.title)
      }
    },
    destroyed() {

    },
  };
</script>

<style lang="less" scoped>
  .news-detail {
    .news-detail-wrapper {
      width: 990px;
      margin: 0 auto;

    }

    .breadcrumb-box {
      font-size: 12px;
      line-height: 80px;
    }

    .detail-box {
      margin-bottom: 150px;
      border-radius: 8px;
      background: #FFFFFF;
      padding: 25px 25px 35px;
      box-shadow: 0px 2px 10px 0px #F5F8FA;

      .title-box {
        padding-bottom: 22px;

        .title {
          font-size: 21px;
          font-weight: bold;
          margin-bottom: 15px;
        }

        .source {
          font-size: 12px;
          color: #666666;

          span {
            margin-right: 35px;
          }
        }
      }
    }

    @media screen and (min-width:1250px) {
      .news-detail-wrapper {
        width: 1200px;
      }

      .breadcrumb-box {
        font-size: 14px;
      }

      .detail-box {
        padding: 30px 30px 38px;

        .title-box {
          .title {
            font-size: 24px;
            font-weight: bold;
          }

          .source {
            font-size: 14px;
            color: #666666;
          }
        }
      }
    }
  }
</style>